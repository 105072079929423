import { Analytics } from '@vercel/analytics/react';
// import logo from './logo.svg';
// import Footer from "./components/Footer";
// import Navbar from "./components/Navbar";
// import Skills from "./components/skills";
// import About from "./components/About";
// import Profile from "./components/profiles";
// import Project from "./components/projects";
// import Home from "./components/home";
// import Contact from "./components/contact";
import Routess from "./router";

function App() {
  return (
    <div>
      <Routess/>
      {/* <Navbar/> */}
      {/* <Home/>
      <About/>
      <Profile/>
      <Skills/>
      <Project/>
      <Contact/>
      <Footer/> */}
    </div>
  );
}

export default App;